import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";
import scrollTo from "alpinejs-scroll-to";

if (process.env.NODE_ENV === "production") {
  console.log("PRODUCTION MODE");
} else {
  console.log("DEV MODE");
  document.getElementById("dev-mode-notice").style.display = "block";
}

window.dataLayer = window.dataLayer || [];
window.gtag = function () {
  dataLayer.push(arguments);
};

window.gtag_register_event = function (sendTo) {
  var s = "";
  switch (sendTo) {
    case "angebot-akzeptieren":
      s = "AW-16480382752/Ow6FCPfKtKgZEKDeurI9";
      break;
    default:
      return;
  }

  gtag("event", "conversion", {
    send_to: s,
  });
};

window.gtag_report_conversion = function (url) {
  var callback = function () {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };
  gtag("event", "conversion", {
    send_to: "AW-16480382752/cJQaCOqCoqgZEKDeurI9",
    event_callback: callback,
  });
  return false;
};

document.addEventListener("DOMContentLoaded", (event) => {
  Alpine.plugin(intersect);
  Alpine.plugin(scrollTo);
  window.alpine = Alpine;
  Alpine.start();
});
